import { createGlobalStyle } from 'styled-components';
import WorkSans from '../assets/fonts/WorkSans/WorkSans-Regular.ttf';
import WorkSansItalic from '../assets/fonts/WorkSans/WorkSans-Italic.ttf';
import WorkSansMedium from '../assets/fonts/WorkSans/WorkSans-Medium.ttf';
import WorkSansSemiBold from '../assets/fonts/WorkSans/WorkSans-SemiBold.ttf';

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: Work Sans;
    src: url(${WorkSans}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: Work Sans;
    src: url(${WorkSansItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
  }

  @font-face {
    font-family: Work Sans;
    src: url(${WorkSansMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: Work Sans;
    src: url(${WorkSansSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }



  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-family: "Work sans", sans-serif;
  }
  
  body {
    min-height: 100vh;
    position: relative;
  }

  button {
    cursor: pointer;
    :disabled {
      cursor: default;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  form {
    width: 100%;
  }

  .infinite-scroll-component {
    overflow: unset !important;
  }

  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

iframe {
    border: 0;
}

ol,
ul {
    list-style: none;
}

a {
    display: inline;
    color: inherit;
    text-decoration: none;
}

iframe {
    border: 0;
}

table {
    border-spacing: 0;
}

button {
    border: 0;
    outline: none;
    background: 0 0;
    cursor: pointer;
    -webkit-appearance: button;
    font: inherit;
}

label {
    [for] {
        cursor: pointer;
    }
}

address {
    font-style: normal;
}

video {
    width: 100%;
}

input,
textarea {
    font: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

`;
