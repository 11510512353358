import { css } from 'styled-components';

export const fontFamily1 = css`
  font-family: 'Work Sans', sans-serif;
`;

export const typographyPreset1 = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
`;

export const typographyPreset2 = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
`;

export const typographyPreset3 = css`
  font-size: 56px;
  font-style: italic;
  font-weight: 500;
  line-height: 66px;
  text-align: center;
`;

export const typographyPreset4 = css`
  font-size: 56px;
  font-weight: 600;
  line-height: 66px;
  text-align: center;
`;

export const typographyPreset5 = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
`;
export const typographyPreset6 = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
`;

export const typographyPreset7 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`;

export const typographyPreset8 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const typographyPreset9 = css`
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
`;

export const typographyPreset10 = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;

export const typographyPreset11 = css`
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
`;

export const typographyPreset12 = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
`;

export const typographyPreset13 = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
`;

export const typographyPreset14 = css`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
`;

export const typographyPreset15 = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
`;

export const typographyPreset16 = css`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
`;

export const typographyPreset17 = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: right;
`;

export const typographyPreset18 = css`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;

export const typographyPreset19 = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;

export const typographyPreset20 = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
`;

export const typographyPreset21 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

export const typographyPreset22 = css`
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
`;

export const typographyPreset23 = css`
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
`;