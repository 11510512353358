import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    palette: {
        gray: '#EAECEB',
        gold: '#F6C035',
        white: '#FFFFFF',
        red: 'red',
        black: '#000000',
        cherry: '#FA5A5A',
        gray100: '#F5F7F6',
        gray300: '#D9DDDB',
        gray500: '#828584',
        gray700: '#525453',
        gray900: '#272828', 
        normalGray: '#FCFDFD',
        lightGray: '#EAECEB',
        textGray: '#5F6361',
        sage: '#D7E1DC',
        primary100: '#FCEABE',
        primary900: '#A78018',
        sectionTwoBackground: '#E7ECE9',
        backgroundTheme: '#FFFCF4',
        milk: '#FCF8F2',
        gray600: '#656766',

},
    breakpoints: {
        mobile: '768'
    },
};